

import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { fireDb } from '../firebase/FirebaseConfig';

function OutlatesList() {
  const [excelData, setExcelData] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [filteredOutlets, setFilteredOutlets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  console.log("Selected City:", selectedCity);

  useEffect(() => {
    const fetchExcelData = async () => {
      try {
        const docRef = doc(fireDb, 'outletsData', 'excelData'); // Reference to Firestore document
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          let data = docSnap.data().data || [];
          console.log("Raw Data: ", data);

          // Transform the data into the desired format
          const formattedData = data.map((item) => ({
            cityName: item['City Name']?.trim() || 'Unknown', // Trim whitespace and handle missing field
            outletAddress: item['Outlet Address']?.trim() || 'No Address Provided',
          }));

          console.log("Formatted Data: ", formattedData);
          setExcelData(formattedData);

          // Extract unique city names from 'cityName' field
          const uniqueCities = Array.from(new Set(formattedData.map(item => item.cityName))).sort();
          console.log("Unique Cities: ", uniqueCities);
          setCities(['All Cities', ...uniqueCities]); // Include default option
        } else {
          console.error('No such document!');
          setError('No data found');
        }
      } catch (error) {
        console.error('Error fetching document:', error);
        setError('Something went wrong while fetching data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchExcelData();
  }, []);

  useEffect(() => {
    if (selectedCity && selectedCity !== 'All Cities') {
      const outlets = excelData.filter(item => item.cityName === selectedCity);
      setFilteredOutlets(outlets);
    } else {
      setFilteredOutlets(excelData); // Show all data when no city is selected or "All Cities" is selected
    }
  }, [selectedCity, excelData]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p style={{ color: '#e74c3c', fontWeight: 'bold', textAlign: 'center' }}>{error}</p>;
  }

  return (
    <div style={{ fontFamily: 'Roboto, sans-serif', padding: '20px', maxWidth: '1000px', margin: 'auto', backgroundColor: '#f5f5f5', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
      <h2 style={{ textAlign: 'center', color: '#2c3e50', fontSize: '28px', marginBottom: '20px' }}>Outlets List</h2>

      {/* Dropdown for city selection */}
      <div style={{ marginBottom: '20px', textAlign: 'center' }}>
        <label htmlFor="city-select" style={{ marginRight: '10px', fontWeight: 'bold', fontSize: '16px', color: '#34495e' }}>Select City:</label>
        <select
          id="city-select"
          value={selectedCity}
          onChange={(e) => setSelectedCity(e.target.value)}
          style={{
            padding: '12px',
            borderRadius: '5px',
            border: '1px solid #ccc',
            fontSize: '16px',
            color: '#34495e',
            backgroundColor: '#ffffff',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
          }}
        >
          {cities.map((city, index) => (
            <option key={index} value={city}>
              {city}
            </option>
          ))}
        </select>
      </div>

      <div>
        {filteredOutlets.length > 0 ? (
          <table
            border="1"
            cellPadding="10"
            style={{
              width: '100%',
              borderCollapse: 'collapse',
              marginTop: '20px',
              border: '1px solid #ddd',
              borderRadius: '8px',
              backgroundColor: '#ffffff',
              boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)',
            }}
          >
            <thead>
              <tr style={{ backgroundColor: '#ecf0f1', textAlign: 'center', fontWeight: 'bold', color: '#2c3e50' }}>
                <th style={{ padding: '12px', border: '1px solid #ddd' }}>City Name</th>
                <th style={{ padding: '12px', border: '1px solid #ddd' }}>Outlet Address</th>
              </tr>
            </thead>
            <tbody>
              {filteredOutlets.map((outlet, index) => (
                <tr key={index} style={{ textAlign: 'center' }}>
                  <td
                    style={{
                      padding: '12px',
                      border: '1px solid #ddd',
                      backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ffffff',
                      color: '#2c3e50',
                    }}
                  >
                    {outlet.cityName}
                  </td>
                  <td
                    style={{
                      padding: '12px',
                      border: '1px solid #ddd',
                      backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ffffff',
                      color: '#2c3e50',
                      textAlign: 'left',
                    }}
                  >
                    {outlet.outletAddress.includes('*') ? (
                      outlet.outletAddress.split('*').map((line, lineIndex) => (
                        <div key={lineIndex} style={{ marginBottom: '5px' }}>
                          <span style={{ fontWeight: 'bold', color: '#16a085' }}>{lineIndex + 1}.</span> {line.trim()}
                        </div>
                      ))
                    ) : (
                      outlet.outletAddress
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p style={{ textAlign: 'center', marginTop: '20px', color: '#7f8c8d' }}>No outlets available for the selected city.</p>
        )}
      </div>
    </div>
  );
}
export default OutlatesList;
