import React from "react";
import "../assest/css/Footer.css";
import { RiInstagramFill } from "react-icons/ri";
import { FaSquareFacebook } from "react-icons/fa6";

const Footer = () => {
  return (
    <footer className="footer">
      <p>© Copyright 2023–24. All Rights Reserved</p>
      <p>Designed by <a href="https://ycsin.com/">Yash Computech Solutions pvt ltd</a></p>
      <div className="social-icons">
        <a href="https://www.facebook.com/ambivineyards?mibextid=LQQJ4d&rdid=URlcqzx1mnMjLZHA&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FX1UNdHQz4JUZHCM3%2F%3Fmibextid%3DLQQJ4d" className="icon"><FaSquareFacebook size={29}/></a>
        <a href="https://www.instagram.com/ambivineyards/?igsh=bXN5aXBvbGExajkz" className="icon"><RiInstagramFill size={30}/></a>
      </div>
    </footer>
  );
};
export default Footer;
