// // import React, { useContext, useState } from "react";
// // import "../assest/css/Header.css";

// // import OutlatesList from "./OutlatesList";
// // import MenuButtons from "./MenuButtons";

// // const Home = () => {
  
// //   const [showOutlatesList, setShowOutlatesList] = useState(false);

// //   return (
// //     <header >
// //       <div className="logo-text-container">
// //           {showOutlatesList ? <OutlatesList /> : <MenuButtons />}
// //       </div>
// //     </header>
// //   );
// // };
// // export default Home;


// import React, { useState } from "react";
// import Header from "./Header";
// import OutlatesList from "./OutlatesList";
// import MenuButtons from "./MenuButtons";

// const Home = () => {
//   const [showOutlatesList, setShowOutlatesList] = useState(false);

//   return (
//     <div>
//       {/* Pass the state and its updater to Header */}
//       <Header
//         showOutlatesList={showOutlatesList}
//         setShowOutlatesList={setShowOutlatesList}
//       />
//       <div className="logo-text-container">
       
//         {showOutlatesList ? <OutlatesList /> : <MenuButtons />}
//       </div>
//     </div>
//   );
// };

// export default Home;


// import React, { useState } from "react";
// import Header from "./Header";
// import OutlatesList from "./OutlatesList";
// import MenuButtons from "./MenuButtons";

// const Home = () => {
//   const [showOutlatesList, setShowOutlatesList] = useState(false);

//   return (
//     <div>
//       {/* Pass the state and its updater to Header */}
//       <Header
//         showOutlatesList={showOutlatesList}
//         setShowOutlatesList={setShowOutlatesList}
//       />
//       <div className="logo-text-container">
//         {/* Render content based on the state */}
//         {showOutlatesList ? <OutlatesList /> : <MenuButtons />}
//       </div>
//     </div>
//   );
// };

// export default Home;

import React, { useState } from "react";
import Header from "./Header";
import OutlatesList from "./OutlatesList";
import MenuButtons from "./MenuButtons";

const Home = () => {
  const [showOutlatesList, setShowOutlatesList] = useState(false);

  return (
    <div>
      <Header
        showOutlatesList={showOutlatesList}
        setShowOutlatesList={setShowOutlatesList}
      />
      <div className="logo-text-container">
        {/* Render content based on the state */}
        {showOutlatesList ? <OutlatesList /> : <MenuButtons />}
      </div>
    </div>
  );
};

export default Home;
