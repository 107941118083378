import React, { useState } from 'react';
import ExcelUploader from './ExcelUploader';
import MenuButtonsLogin from './MenuButtonsLogin';
import { fireDb } from '../firebase/FirebaseConfig';
import * as XLSX from 'xlsx';
import { doc, setDoc } from 'firebase/firestore';

function IndexLogin() {
  const [excelData, setExcelData] = useState([]);
  const [uploadStatus, setUploadStatus] = useState('');
  console.log("excelData------->", excelData);

  // Handle file upload and parse the Excel file
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = async (e) => {
        const binaryString = e.target.result;
        const wb = XLSX.read(binaryString, { type: 'binary' });
        const sheetName = wb.SheetNames[0];  // Read the first sheet
        const sheet = wb.Sheets[sheetName];
        const data = XLSX.utils.sheet_to_json(sheet);  // Convert the sheet to JSON
        
        // Modify the data to split the Outlet Address
        const modifiedData = data.map((row) => {
          if (row['Outlet Address']) {
            // Split the 'Outlet Address' by '*' and exclude the first index
            const splitAddress = row['Outlet Address'].split('*').slice(1).join('*');
            return {
              ...row,
              'Outlet Address': splitAddress,  // Update the Outlet Address
            };
          }
          return row;
        });

        setExcelData(modifiedData);  // Store the modified data in state

        // Add or replace data in Firestore
        try {
          const docRef = doc(fireDb, 'outletsData', 'excelData');  // Use a fixed document ID to overwrite it
          await setDoc(docRef, { data: modifiedData, uploadedAt: new Date() });  // Set data to Firestore
          setUploadStatus('File uploaded successfully');
        } catch (error) {
          console.error('Something went wrong.', error);
          setUploadStatus('Something went wrong while uploading the file. Please try again.');
        }
      };

      reader.readAsBinaryString(file);
    }
  };

  return (
    <div>
      <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px', maxWidth: '800px', margin: 'auto' }}>
        <h2 style={{ textAlign: 'center', color: '#333', fontSize: '24px' }}>Upload and View Excel Data</h2>
        
        <input
          type="file"
          accept=".xlsx, .xls"
          onChange={handleFileUpload}
          style={{
            padding: '10px',
            marginBottom: '20px',
            borderRadius: '5px',
            border: '1px solid #ccc',
            display: 'block',
            width: '100%',
            cursor: 'pointer',
            fontSize: '16px'
          }}
        />
        
        {uploadStatus && (
          <div style={{
            marginTop: '20px',
            fontWeight: 'bold',
            color: uploadStatus === 'File uploaded successfully' ? 'green' : 'red',
            textAlign: 'center'
          }}>
            {uploadStatus}
          </div>
        )}

        {excelData.length > 0 && (
          <div style={{ marginTop: '30px' }}>
            <table
              border="1"
              cellPadding="10"
              style={{
                width: '100%',
                borderCollapse: 'collapse',
                marginTop: '20px',
                border: '1px solid #ddd',
                borderRadius: '8px',
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
              }}
            >
              <thead>
                <tr style={{ backgroundColor: '#f4f4f4', textAlign: 'center', fontWeight: 'bold' }}>
                  {Object.keys(excelData[0]).map((key, index) => (
                    <th key={index} style={{ padding: '12px', border: '1px solid #ddd', color: '#333' }}>{key}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
  {excelData.map((row, index) => (
    <tr key={index} style={{ textAlign: 'center' }}>
      {Object.entries(row).map(([key, value], cellIndex) => (
        <td
          key={cellIndex}
          style={{
            padding: '10px',
            border: '1px solid #ddd',
            backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#fff',
            color: '#333',
          }}
        >
          {key === 'Outlet Address' && typeof value === 'string' && value.includes('*') ? (
            value.split('*').map((line, lineIndex) => (
              <div key={lineIndex} style={{ textAlign: 'left',margin : '10px 0px' }}>
              <span style={{fontWeight:'800'}}>{lineIndex + 1}.</span>  {line.trim()}
              </div>
            ))
          ) : (
            value
          )}
        </td>
      ))}
    </tr>
  ))}
</tbody>

            </table>
          </div>
        )}
      </div>

      <ExcelUploader />
      <MenuButtonsLogin />
    </div>
  );
}

export default IndexLogin;
