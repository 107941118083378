import React, { useState } from 'react'
import Header from './Header'
import MenuButtons from './MenuButtons'
import Footer from './Footer'
import { useSearchParams } from 'react-router-dom'
import OutlatesList from './OutlatesList'
import Home from './Home'


function Index() {

  console.log("--------------test------------------------------Index")
  const [searchParams] = useSearchParams();
  const outletName = searchParams.get('outlet_name')
  const [showOutlatesList, setShowOutlatesList] = useState(false);
console.log("outletNames-----------Index------------>",outletName)

  return (
    <div>

    
     <Home/>
      
{/* <OutlatesList/> */}
      <Footer />
    </div>
  )
}

export default Index

