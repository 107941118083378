
// import React from "react";
// import "../assest/css/Header.css";
// import logo from "../assest/images/Logo.png";
// import { TextGenerateEffectDemo } from "../components/ui/textGenerateEffect/TextGenerateComponent";
// import { TextGenerateEffectDemo2 } from "../components/ui/textGenerateEffect/TextGenerateComponent2";
// import { useNavigate, useSearchParams } from "react-router-dom";

// const Header = ({ showOutlatesList, setShowOutlatesList }) => {
//   const navigate = useNavigate();
//   const [searchParams] = useSearchParams();
//   const outletName = searchParams.get("outlet_name");

//   const handleLogin = () => {
//     navigate(`/login?outlet_name=${encodeURIComponent(outletName)}`);
//   };

//   const handleOutlatesToggle = () => {
//     setShowOutlatesList((prevState) => !prevState); // Toggle the state
//   };

//   return (
//     <header className="header">
//       <div className="buttons"
//         style={{
//           display: "flex",
//           justifyContent: "space-between",
//           padding: "10px",
//         }}
//       >
//         <button className="outlates-button" onClick={handleOutlatesToggle}>
//           {showOutlatesList ? "All Wines List" : "Outlets List"}
//         </button>
//         <button className="outlates-button" >
//           {/* {showOutlatesList ? "All Wines List" : "Outlets List"} */}
//           All Wines
//         </button>
//         <button onClick={handleLogin} className="login-button-home">
//           Login
//         </button>
//       </div>

//       <div className="logo-text-container">
//         <img
//           src={logo}
//           alt="Logo"
//           className="flip-animation"
//           style={{ height: 200, width: 200, borderRadius: 50 }}
//         />
//         <div className="text-effect-container">
//           <TextGenerateEffectDemo />
//           <TextGenerateEffectDemo2 />
//         </div>
//       </div>
//     </header>
//   );
// };

// export default Header;
// // when user click on all wines button show data of all wines and when user click on all outlet list so open outlets list on this component


import React from "react";
import "../assest/css/Header.css";
import logo from "../assest/images/Logo.png";
import { TextGenerateEffectDemo } from "../components/ui/textGenerateEffect/TextGenerateComponent";
import { TextGenerateEffectDemo2 } from "../components/ui/textGenerateEffect/TextGenerateComponent2";
import { useNavigate, useSearchParams } from "react-router-dom";

const Header = ({ showOutlatesList, setShowOutlatesList }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const outletName = searchParams.get("outlet_name");

  const handleLogin = () => {
    navigate(`/login?outlet_name=${encodeURIComponent(outletName)}`);
  };

  const handleOutlatesToggle = () => {
    setShowOutlatesList(true); // Show outlets list
  };

  const handleAllWinesToggle = () => {
    setShowOutlatesList(false); // Show wines
  };

  return (
    <header className="header">
      <div
        className="buttons"
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px",
        }}
      >
        <button className="outlates-button" onClick={handleOutlatesToggle}>
          Outlets List
        </button>
        <button className="outlates-button" onClick={handleAllWinesToggle}>
          All Wines
        </button>
        <button onClick={handleLogin} className="login-button-home">
          Login
        </button>
      </div>

      <div className="logo-text-container">
        <img
          src={logo}
          alt="Logo"
          className="flip-animation"
          style={{ height: 200, width: 200, borderRadius: 50 }}
        />
        <div className="text-effect-container">
          <TextGenerateEffectDemo />
          <TextGenerateEffectDemo2 />
        </div>
      </div>
    </header>
  );
};

export default Header;
