import React, { useContext, useEffect, useState } from 'react';
import '../assest/css/LoginScreen.css'; // Import the CSS file for styling
import logo from '../assest/images/logo.jpeg'; // Make sure to replace this with your logo path
import {  useNavigate, useSearchParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { fireDb } from '../firebase/FirebaseConfig'; // Adjust the path to your Firebase config
import { MenuContaxt } from './Contaxt';

function LoginScreen() {

  const navigate = useNavigate();

  const {outletName} = useContext(MenuContaxt)

  console.log("outletNames-----------LoginScreen------------>",outletName)

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [outletData, setOutletData] = useState(null); // State to store the fetched data
  const [loading, setLoading] = useState(true); // State to track loading
  const [error, setError] = useState(null); // State to track any errors
  const [filteredOutlets, setFilteredOutlets] = useState([]); // State to store filtered outlets
  const [showDropdown, setShowDropdown] = useState(false); // State to show/hide dropdown

  // Function to fetch the data from Firestore
  const fetchOutletData = async () => {
    const adminDocRef = doc(fireDb, 'admin', 'importedData'); // Reference to the 'admin' collection and the document ID 'importedData'
    try {
      const docSnap = await getDoc(adminDocRef);
      console.log("docSnap------>",docSnap.data().data)
      if (docSnap.exists()) {
        setOutletData(docSnap.data().data); // Set the fetched data to state
      } else {
        console.log('No such document!');
        setError('No such document found');
      }
    } catch (error) {
      console.error('Error fetching document:', error);
      setError('Error fetching document');
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchOutletData();
  }, []); // Fetch the data when the component mounts

 // Function to handle email submission and filter outlets
const handleEmailSubmit = (e) => {
  e.preventDefault(); // Prevent page refresh on form submission

  // Check if email and password match
  if (email !== password) {
    alert('Check your email and password'); 
      return; // Exit the function early
  }

  if (email && outletData) {
      // Filter the outletData based on the entered email
      const matchedOutlets = outletData.filter(
          (outlet) => outlet.EMAIL === email // Assuming EMAIL field exists in your data
      );

      if (matchedOutlets.length > 0) {
          setFilteredOutlets(matchedOutlets[0].STORE_NAME); // Set the filtered outlets to state
          setShowDropdown(true); // Show dropdown when email is submitted
          console.log("Matched outlets:", matchedOutlets[0].STORE_NAME);
          // Navigate to '/uploading' and send the data
          // navigate('/uploading', { 
          //     state: matchedOutlets, 
          //     replace: true  // Optional: This will replace the current history entry
          // });

          navigate(`/uploading?outlet_name=${encodeURIComponent(outletName)}`,{ replace: true , state: matchedOutlets });


      } else {
          setShowDropdown(false); // Hide dropdown if no outlets are found
          console.log("No outlets found for this email.");
          alert('No outlets found for this email.')
      }
  }
};

  // Display loading state, error, or the data
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container" style={{   
  margin: '0px 35px  '
       }}>
      <div className="login-container">
        <img src={logo} alt="Company Logo" className="logo" />
        <h2 className="login-title">Welcome Back!</h2>
        <form onSubmit={handleEmailSubmit} className="login-form">
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)} // Update email state on input change
            placeholder="Enter email address"
            required
            className="login-input"
          />
          <div className="password-container">
            <input
              type={passwordVisible ? 'text' : 'password'}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)} // Update password state
              required
              className="login-input"
            />
            <span
              onClick={() => setPasswordVisible(!passwordVisible)}
              className="toggle-password"
            >
              {passwordVisible ? '👁️' : '👁️‍🗨️'}
            </span>
          </div>
          <button type="submit" className="login-button">Log In</button>
        </form>

        {/* Dropdown to display filtered outlets */}
        {showDropdown && filteredOutlets.length > 0 && (
          <div>
            <label htmlFor="storeSelect">Select Outlet:</label>
            <select id="storeSelect">
              {filteredOutlets.map((storeName, index) => (
                <option key={index} value={storeName}>
                  {storeName}
                </option>
              ))}
            </select>
          </div>
        )}

        {/* If no outlets found */}
        {showDropdown && filteredOutlets.length === 0 && (
          <div>No outlets found for this email.</div>
        )}
      </div>
    </div>
  );
}

export default LoginScreen;















